import { CanaisDeTVComponent } from './canais-de-tv/canais-de-tv.component';
import { ProgramacaoComponent } from './programacao/programacao.component';
import { IndexComponent } from './index/index.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'programacao', component: ProgramacaoComponent },
  { path: 'canaisdetv', component: CanaisDeTVComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
