<h2>ACOMPANHE A DESPERTAR NA TV</h2>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel>
          <ng-template ngbPanelTitle class="button">
            <span>Paraná</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <a href="http://www.domustelecom.com.br/" target="_blank">DOMUS TELECOM</a> - Canal 52
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
