import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';


declare var Clappr: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class IndexComponent implements OnInit, AfterViewInit {

  player:any;

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.player = new Clappr.Player({
      source: "https://f1.firefont.tk/despertar1/video.m3u8", parentId: "#player",
      autoPlay: true,
      width: '100%'
    });
    this.resizePlayer();
    this.updateProgram();

  }
  resizePlayer(){
    console.log($(document).height());

    var aspectRatio = 9/16,
    newWidth = $(window).width(),
    newHeight = 2 * Math.round(newWidth * aspectRatio/2);
    console.log(newHeight);

    if(newHeight > ($(window).height() - 61.5 - 40 - 14)){
      newHeight = $(window).height() - 61.5 - 40 - 14;
    }

    this.player.resize({width: '100%', height: newHeight});
  }

  onResize(event) {
    console.log("resized");
    setTimeout(()=>{
      this.resizePlayer();
    },500);
  }

  program_now_start_time: number = null;
  program_now_end_time: number = null;
  program_now_name: string = null;

  program_next_start_time: number = null;
  program_next_end_time: number = null;
  program_next_name: string = null;

  updateProgram(){
    this.http.get<any>('http://wowza.firefont.tk:8080/api/getProgram/1').subscribe(data => {
      if(data.ok == 1){
        if(data.data.has_actual == 1){
          this.program_now_start_time = data.data.actual.start_time;
          this.program_now_end_time = data.data.actual.end_time;
          this.program_now_name = data.data.actual.name;
        }else{
          this.program_now_start_time = null;
          this.program_now_end_time =  Math.round(new Date().getTime()/1000) + 10;
          this.program_now_name = null;
        }
        if(data.data.has_next == 1){
          this.program_next_start_time = data.data.next.start_time;
          this.program_next_end_time = data.data.next.end_time;
          this.program_next_name = data.data.next.name;
        }else{
          this.program_next_start_time = null;
          this.program_next_end_time =  null;
          this.program_next_name = null;
        }
      }else{
        this.program_now_start_time = null;
        this.program_now_end_time =  Math.round(new Date().getTime()/1000) + 10;
        this.program_now_name = null;
      }
      setTimeout(()=>{
        this.setTimeforUpdate();
      },500);
    });
  }

  setTimeforUpdate(){
    var now_time = Math.round(new Date().getTime()/1000);
    var time_for_timeout = this.program_now_end_time - now_time;
    console.log(time_for_timeout);
    setTimeout(()=>{
      this.updateProgram();
    },time_for_timeout * 1000);
  }


}
