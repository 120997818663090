<h2>ACOMPANHE A PROGRAMAÇÃO AO VIVO DA DESPERTAR TV</h2>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel title="Domingo">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Segunda">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Terça">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Quarta">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Quinta">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Sexta">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Sábado">
          <ng-template ngbPanelContent>
            Não Há Programação Ao Vivo.
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
