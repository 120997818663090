import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'jpl-site';


  constructor(private http: HttpClient) { }
  ngAfterViewInit(): void {
    // this.updateProgram();
  }

  program_now_start_time: number = null;
  program_now_end_time: number = null;
  program_now_name: string = null;

  program_next_start_time: number = null;
  program_next_end_time: number = null;
  program_next_name: string = null;

  updateProgram(){
    this.http.get<any>('https://wowmanager.jpl.tv.br/api/getProgram/1').subscribe(data => {
      if(data.ok == 1){
        if(data.data.has_actual == 1){
          this.program_now_start_time = data.data.actual.start_time;
          this.program_now_end_time = data.data.actual.end_time;
          this.program_now_name = data.data.actual.name;
        }else{
          this.program_now_start_time = null;
          this.program_now_end_time =  Math.round(new Date().getTime()/1000) + 10;
          this.program_now_name = null;
        }
        if(data.data.has_next == 1){
          this.program_next_start_time = data.data.next.start_time;
          this.program_next_end_time = data.data.next.end_time;
          this.program_next_name = data.data.next.name;
        }else{
          this.program_next_start_time = null;
          this.program_next_end_time =  null;
          this.program_next_name = null;
        }
      }else{
        this.program_now_start_time = null;
        this.program_now_end_time =  Math.round(new Date().getTime()/1000) + 10;
        this.program_now_name = null;
      }
      setTimeout(()=>{
        this.setTimeforUpdate();
      },500);
    });
  }

  setTimeforUpdate(){
    var now_time = Math.round(new Date().getTime()/1000);
    var time_for_timeout = this.program_now_end_time - now_time;
    console.log(time_for_timeout);
    setTimeout(()=>{
      this.updateProgram();
    },time_for_timeout * 1000);
  }

  abrir_player_popup() {
    window.open("https://player.srvstm.com/player-popup-responsivo/7714/1", "", "width=500,height=290,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=NO");
  }
}
