<header>
  <nav class="navbar navbar-expand-md fixed-top navbar-dark bg-primary bg-c2">
    <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
      <ul class="navbar-nav mr-auto" id="program_ul">
        <li class="nav-item">
          <a class="nav-link" href="https://play.google.com/store/apps/details?id=br.com.vertentedevida.despertar.radio" target="_blank">App Rádio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://play.google.com/store/apps/details?id=br.tv.despertar.app" target="_blank">App TV</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://play.google.com/store/apps/details?id=br.com.vertentedevida.despertar.biblia" target="_blank">App Bíblia</a>
        </li>
      </ul>
    </div>
    <div class="mx-auto order-0">
      <a class="navbar-brand mx-auto" routerLink="/">
        <img src="/assets/logo_despertar.png" height="34"/>
      </a>
    </div>
    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="https://f3.firefont.tk/DESPERTARTV/video.m3u8" target="_blank">Link do IPTV</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="abrir_player_popup()">Rádio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/canaisdetv">Canais de TV</a>
        </li>
      </ul>
    </div>
  </nav>

</header>
<router-outlet></router-outlet>
<footer class="mt-2 p-2 text-center">
  &copy; Despertar TV 2021 - Todos os direitos reservados.
</footer>
