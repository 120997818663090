import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  templateUrl: './canais-de-tv.component.html',
  styleUrls: ['./canais-de-tv.component.scss']
})
export class CanaisDeTVComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.collapse').collapse()
  }

}
